@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.button {
  z-index: 50;
  width: vw_d(28);
  height: vw_d(27.7);
  margin-right: vw_d(11);
  transition: none;
  border-radius: 50%;

  @include screen('mobile') {
    width: vw(43.6);
    height: vw(43.1);
    margin-right: vw(18);
    // transform: scale(1.2);
  }
  &:hover {
    i {
      svg {
        ellipse {
          fill: #000 !important;
        }
        path {
          fill: #f3c52a !important;
        }
      }
    }
  }
  i {
    display: block;
    width: 100% !important;
    height: 100% !important;
    transition: all ease 0.3s;
    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
      ellipse,
      path {
        transition: all ease 0.3s;
      }
    }
  }
}

// Accessibility Styles
:global {
  body {
    &.acc-grayscale,
    &.acc-negative {
      overflow-x: hidden;
    }
  }

  div#accessibility {
    position: absolute !important;
    z-index: 45 !important;
    border: 0 !important;
    background: transparent !important;
    box-shadow: none !important;

    @include screen('non-mobile') {
      top: 29px !important;

      html[dir='ltr'] & {
        right: 65px !important;
        left: auto !important;
      }

      html[dir='rtl'] & {
        right: auto !important;
        left: 65px !important;
      }
    }

    @include screen('mobile') {
      top: auto !important;
      right: vw(10 * 2) !important;
      bottom: vw(15 * 2) !important;
      left: auto !important;
      z-index: 45 !important;
    }

    div#acc-header {
      .image {
        background: none !important;

        @include screen('mobile') {
          right: 0 !important;
          left: auto !important;

          html[dir='rtl'] & {
            right: 0 !important;
            left: auto !important;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 25px;
          height: 25px;
          transform: translate(-50%, -50%);
        }

        html[dir='ltr'] & {
          right: 0 !important;
          left: auto !important;
        }

        html[dir='rtl'] & {
          right: auto !important;
          left: 0 !important;
        }

        img {
          display: none !important;
        }
      }

      .text {
        visibility: hidden;
      }
    }

    button#acc-regular {
      @include screen('non-mobile') {
        &:focus {
          outline: transparent auto 5px !important;
        }
      }
    }

    div#acc-footer {
      display: none !important;
    }
  }

  input,
  textarea {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset;
      color: black;
    }
  }

  .overflow {
    overflow: hidden;
  }

  #INDmenu-btn {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      // background-color: red;
      // border: 1px solid red;

      @include screen('mobile') {
        width: vw(23 * 2);
        height: vw(23 * 2);
        background-color: white;
      }
    }
  }

  #INDbtnWrap {
    #INDmenu-btn {
      position: fixed;
      top: vw_d(20) !important;
      right: initial;
      left: vw_d(20) !important;
      width: vw_d(40);
      height: vw_d(40);
      margin: 0;
      transition: none !important;
      opacity: 0 !important;
      visibility: hidden !important;

      @include screen('mobile') {
        position: absolute;
        top: vw(19) !important;
        left: vw(20) !important;
        width: vw(44);
        height: vw(44);
      }
    }
  }

  #INDmenu-btn svg {
    visibility: hidden;
  }
}
