.media-field{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &.error{
    .error-msg{
      display: block;
    }
  }

  label{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;

    &.profile-photo-label{
      &:before{
        display: none;
      }
    }

    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba($color: #000000, $alpha: 0.35);
    }

    .wrapper{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .error-msg{
      position: relative;
      font-size: 15px;
      color: rgba(194, 76, 76, 0.85);
      display: none;
    }
    input{
      display: none;
    }

    .photo-wrapper{
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: dashed 1px rgba($color: #000000, $alpha: 0.73);
      border-radius: 50%;
      background-color: rgba($color: #5D5D5D, $alpha: 0.36);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;

      .empty-user-icon{
        display: block;
        width: 55px;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .camera-img{
      position: absolute;
      left: -10px;
      bottom: -10px;
    }
  }
}