@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.header {
  width: 100%;
  height: vw_d(250);
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: vw_d(22);
  position: relative;

  @include screen('mobile') {
    height: vw(257);
    padding: vw(25) vw(20) 0;
    margin-bottom: vw(22);
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .header-logo-desk {
    width: vw_d(770);
    margin-top: vw_d(-15);

    @include screen('mobile') {
      display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .header-logo-mob {
    display: none;

    @include screen('mobile') {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .socialls-wrapper {
    position: absolute;
    top: vw_d(15);
    left: vw_d(20);
    display: flex;
    @include screen('mobile') {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      justify-content: center;
    }
  }
}
