@import 'modern-css-reset/dist/reset.css';
@import 'mixins';

html {
	font-size: 62.5%; // => 10px => 1rem
	scroll-behavior: smooth;

	//  Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;

	// Font variant
	-webkit-font-variant-ligatures: none;
	font-variant-ligatures: none;

	// Smoothing
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

	// Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
	// we force a non-overlapping, non-auto-hiding scrollbar to counteract.
	-ms-overflow-style: scrollbar;

	// Change the default tap highlight to be completely transparent in iOS.
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: 100;
	// font-family: $medium-font;

  &[class^="no-scroll-"] {
    overflow: hidden;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--ria-input-color);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
	padding: 0;
	border: 0;
	outline: none;
	background: none;
	box-shadow: none;
	cursor: pointer;
	text-align: center;
	color: inherit;
}
