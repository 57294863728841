@import 'routes/client/styles/mixins.scss';

.main {
  position: relative;
  min-height: 100vh;
  width: 100%;
  // max-width: vw_d(1260);
  // padding: 0 vw_d(10);
  margin: 0 auto;
  @include screen('mobile') {
    max-width: 100%;
    padding: 0;
  }
}
