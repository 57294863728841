@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
	font-family: 'NarkisBlock-Light';
	src: url('/src/routes/client/assets/fonts/NarkissBlock-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkisBlock-Medium';
	src: url('/src/routes/client/assets/fonts/NarkissBlock-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkisBlock-Semibold';
	src: url('/src/routes/client/assets/fonts/NarkissBlock-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
