@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.gallery-wrapper {
  width: 100%;

  .top {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: vw_d(20);

    @include screen('mobile') {
      padding-bottom: vw(25);
    }

    .text-block {
      width: vw_d(410);
      background-color: #f3c52a;
      display: flex;
      font-size: vw_d(20);
      color: #000;
      font-family: $semi-bold-font;
      align-items: center;
      justify-content: space-around;
      @include screen('mobile') {
        width: auto;
        font-size: vw(30);
        width: auto;
        flex-shrink: 0;
        margin-left: vw(19);
        padding: 0 vw(23);
      }
    }

    .color-block {
      width: vw_d(845);
      background-color: #bbbbbb;
      @include screen('mobile') {
        flex-grow: 1;
      }
    }
  }

  .items {
    // display: flex;
    // justify-content: flex-start;
    // align-items: stretch;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, vw_d(410));
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: vw_d(25);
    grid-row-gap: vw_d(20);
    padding-bottom: vw_d(13);

    @include screen('mobile') {
      display: block;
      padding: 0 vw(25) 0;
    }

    .item {
      // width: vw_d(410);
      // margin-bottom: vw_d(20);
      // margin-left: vw_d(25);
      transition: all ease 0.3s;
      // &:nth-child(3n + 3) {
      //   margin-left: 0;
      // }

      @include screen('mobile') {
        width: 100%;
        margin-bottom: vw(28);
        margin-left: 0;
      }

      &:hover {
        transform-origin: center center;
        transform: scale(1.04);

        @include screen('mobile') {
          transform: scale(1);
        }
      }

      a {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        .image {
          height: vw_d(300);
          min-height: vw_d(300);
          flex-shrink: 0;
          background-size: 100%;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          @include screen('mobile') {
            height: vw(510);
            min-height: vw(510);
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;
          background-color: #f3c425;
          padding: vw_d(4) vw_d(11) vw_d(3);
          @include screen('mobile') {
            padding: vw(5) vw(29) vw(7);
          }
          .title {
            padding-bottom: vw_d(5);
            font-size: vw_d(25);
            line-height: vw_d(26);
            font-family: $medium-font;
            color: #000;
            @include screen('mobile') {
              font-size: vw(36);
              line-height: vw(39);
            }
          }

          .sub-title {
            font-size: vw_d(20);
            line-height: vw_d(26);
            font-family: $light-font;
            color: #000;
            word-wrap: break-word;
            @include screen('mobile') {
              font-size: vw(29);
              line-height: vw(39);
            }
          }
        }
      }
    }
  }
}
